import type { Comment } from '../comments';
import type { PaymentMode, Payment, PaymentType } from '../payments';
import type { Procedure, ShippingLabelSize } from '../retailers';
import type { Address, ElasticSearchSortOptions, SortOptions } from '../SharedTypes';

export enum OrderGroupType {
  EXCHANGE = 'EXCHANGE',
}

export interface OrderTimeline {
  id: string;
  swftboxTracking: string;
  state: string;
  stage: string;
  status: string;
  userId: string;
  custodianId: string;
  custodianType: string;
  action: string;
  description: string;
  partnerDescription?: string;
  date: string;
  failedAttempt?: string;
  failedAttemptReason?: string;
  isMilestone: boolean;
  orderUpdates?: string[];
  fulfillment: {
    id: string;
    name: string;
  };
  customer: {
    name: string;
  };
  dispatcher: {
    name: string;
  };
  driver: {
    name: string;
  };
  retailer: {
    name: string;
  };
  actionByUser: {
    id: string;
    name: string;
    roles: string[];
  };
}

export interface OrderStateStageFilter {
  states?: OrderState[];
  stages?: OrderStage[];
}

export enum OrderSortableFields {
  createdAt = 'createdAt',
  expectedDropDateStart = 'expectedDrop',
}

export interface OrderSortFilter {
  order?: SortOptions | ElasticSearchSortOptions;
  orderBy?: OrderSortableFields;
}

export enum OrderStage {
  failed = 'failed',
  transit = 'transit',
  lastMile = 'lastMile',
  rto = 'rto',
}

export enum OrderShippingFlag {
  routeReady = 'routeReady',
  routeLocked = 'routeLocked',
  skipRouting = 'skipRouting',
  failedToRoute = 'failedToRoute',
}

export enum OrderState {
  draft = 'draft',
  requested = 'requested',
  fulfillment = 'fulfillment',
  inJob = 'inJob',
  warehouse = 'warehouse',
  delivered = 'delivered',
  cancelled = 'cancelled',
  rtoComplete = 'rtoComplete',
}

interface Customer {
  id: string;
  name: string;
  phone: string;
  email?: string;
}

interface Retailer {
  id: string;
  name: string;
  phone: string;
  logo?: string;
  shippingLabelSize: ShippingLabelSize;
  showBrand: boolean;
  procedures?: Procedure[];
}

interface OrderTiming {
  inputDate: string;
  actualPickupDateStart: string | null;
  actualPickupDateEnd: string | null;
  actualDropDateStart: string | null;
  actualDropDateEnd: string | null;
  expectedPickupDateStart: string | null;
  expectedPickupDateEnd: string | null;
  expectedDropDateStart: string | null;
  expectedDropDateEnd: string | null;
  slaStart: string | null;
  slaEnd: string | null;
}

interface OrderDropProfile {
  key: string;
  profileType: string;
  name: string;
}

export interface SimpleItem {
  id: string;
  quantity: number;
  price?: number;
  weight?: number;
  weightUnit?: string;
  description?: string;
}

export enum OrderValidationStatus {
  VALIDATING = 'validating',
  VALIDATED = 'validated',
  FAILED = 'failed',
}

export interface ScheduledCustomerSlot {
  date?: string;
  from: string;
  to: string;
}

export interface ScheduledPickupSlot {
  date: string;
  from: string;
}

export interface GroupOrder {
  id: string;
  isReverse: boolean;
  notes: string;
  packageCount: number;
  partnerStatus: string;
  reference: string;
  state: OrderState;
  status: string;
  swftboxTracking: string;
}

export enum OrderFlagType {
  merchant = 'merchant',
  cs = 'cs',
  star = 'star',
}

export enum OrderFlagStatus {
  pending = 'pending',
  actionTaken = 'actionTaken',
  resolved = 'resolved',
}

export interface OrderFlag {
  id: string;
  orderId: string;
  retailerId: string;
  flagType: OrderFlagType;
  flagReason?: string | null;
  resolution?: string | null;
  status: OrderFlagStatus;
  flaggedBy?: string | null;
  resolvedBy?: string | null;
  resolvedAt?: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface SimpleOrder {
  id: string;
  driverName: string;
  driverPhone: string;
  routeId: string;
  status: string;
  reference: string;
  swftboxTracking: string;
  retailerTracking?: string;
  brandName?: string;
  paymentAmount: number;
  currency: string;
  paymentMode: PaymentMode;
  isReverse: boolean;
  state: OrderState;
  stage: OrderStage;
  shippingFlag: null;
  failedAttemptCount: number;
  arrivingShortly: boolean;
  validationStatus: OrderValidationStatus;
  shouldLeaveAtTheDoor: boolean;
  b2bHandling?: boolean;
  b2bInvoice?: string[];
  isExpress: boolean;
  notes?: string;
  fulfillmentRemarks?: string;
  to: Address;
  from: Address;
  createdAt: string;
  updatedAt: string;
  customer: Customer;
  retailer: Retailer;
  requireDeliveryNote: boolean;
  requireProofOfId: boolean;
  scheduledCustomerSlot?: ScheduledCustomerSlot;
  allowCustomerProofSignature: boolean;
  packageCount: number;
  comments: Comment[];
  attachments?: string[];
  items: SimpleItem[];
  integration?: string | null;
  deliveryNoteUrls?: string[];
  proofOfId?: string[];
  customerProofSignatureUrl?: string;
  groupId?: string;
  groupedOrders?: GroupOrder[];
  groupType?: string;
  originalOrderId?: string;
  payment?: Payment;
  requireOtpOnDelivery?: boolean;
  isDeliveryOtpVerified?: boolean;
  isBoosted?: boolean;
  isInternational?: boolean;
  scheduledPickupSlot?: ScheduledPickupSlot;
  oversized?: boolean;
  isRemote?: boolean;
  isOutOfZone?: boolean;
  chilled?: boolean;
}

export interface Order extends SimpleOrder {
  timing: OrderTiming;
  dropProfile?: OrderDropProfile;
  orderFlags?: OrderFlag[];
  internationalDetails?: InternationalDetails;
  originalPayload?: Record<string, any>;
}

export enum OrderPaymentMethod {
  PREPAID = 'prePaid',
  PAYMENT_ON_DELIVERY = 'paymentOnDelivery',
}

export interface UpdateOrderInput {
  id: string;
  notes?: string | null;
  fulfillmentRemarks?: string | null;
  requireDeliveryNote?: boolean;
  packageCount?: number;
  validationStatus?: 'validated' | 'failed';
  isInternational?: boolean;
  isRemote?: boolean;
  isOutOfZone?: boolean;
  oversized?: boolean;
  chilled?: boolean;
  b2bHandling?: boolean;
}

export interface OrderFlagInput {
  orderId: string;
  flagType?: string | null;
  flagReason?: string | null;
}

export interface OrdersFlagInput {
  orderIds: string[];
  flagType: OrderFlagType;
  flagReason?: string;
}

export interface UpdateOrderFlagInput {
  id: string;
  flagType?: string | null;
  flagReason?: string | null;
  status: OrderFlagStatus;
}

export interface UpdateOrderPaymentInput {
  id: string;
  paymentAmount?: number | null;
  paymentMode?: string;
}

export interface OrderQueryArgs {
  page: number;
  perPage: number;
  stages?: OrderStage[];
  states?: OrderState[];
  shippingFlag?: OrderShippingFlag;
  startDate?: string;
  endDate?: string;
}

export interface CountsResponse {
  counts: Record<string, number>; // until finalize the continues changes in statuses
}

export interface InsightsResponse {
  todayDelivered: number;
  todaySuccessRate: number;
  yesterdaySuccessRate: number;
  yesterdayDelivered: number;
  thisMonthDelivered: number;
  thisMonthSuccessRate: number;
}

export interface CustomerForceDropInput {
  swftboxTracking: string;
  driverId: string;
  paymentAmount?: number;
  paymentType?: PaymentType;
  deliverDateTime?: string;
  receipt?: File | string;
  paymentMode: PaymentMode;
  transactionId?: number;
}

export interface ForceRtoCompleteInput {
  swftboxTracking: string;
  driverId: string;
  rtoDateTime?: string;
}

export interface UpdateOrderDropProfileInput {
  id: string;
  dropProfileId: string;
  scheduledCustomerSlot?: ScheduledCustomerSlot;
  scheduledPickupSlot?: ScheduledPickupSlot;
}

export interface FlaggedOrdersCount {
  flagged: number;
  csCheck: number;
  starred: number;
  [key: string]: number;
}

export interface OrderInsights extends FlaggedOrdersCount {
  pickupRequested: number;
}

export interface CarriyoIntegrationCarrier {
  id: string;
  createdAt: string;
  updatedAt: string;
  accountName: string;
  accountId: string;
  isActive: boolean;
}

export interface CreateCarriyoIntegrationCarrierInput {
  accountId: string;
  accountName: string;
}

export interface UpdateCarriyoIntegrationCarrierInput extends CreateCarriyoIntegrationCarrierInput {
  id: string;
  isActive: boolean;
}

export interface CarriyoIntegrationError {
  level: string;
  trigger: string;
  source: string;
  type: string;
  message: string;
}

export interface InternationalDetails {
  id: string;
  carrierId?: string;
  carrierName?: string;
  shippingLabelUrl?: string;
  status?: string;
  trackingUrl?: string;
  commercialInvoiceUrl?: string;
  estimatedShippingCost?: string;
  estimatedDeliveryDate?: string;
  isAssigned?: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  errors?: CarriyoIntegrationError[];
}

export interface PackageDetails {
  dimensions: {
    depth: number;
    height: number;
    unit: string;
    width: number;
  };
  reference: string;
  weight: number;
  weightUnit: string;
}

export interface AssignInternationalInput {
  orderId: string;
  carrierId: string;
  description: string;
  totalItemsPrice: number;
  packageDetails: PackageDetails[];
  postCode?: string;
  stateCode?: string;
}
