import { Badge, Box, Button, HStack, Text, toast, Tooltip, VStack } from '@swftbox/style-guide';
import { useOrderModalActions, type Order } from 'src/components/Particles';
import { OrderCheckbox } from '../TableCells';
import { useMemo } from 'react';
import { type OrdersStatusFilter } from 'src/components/Pages/Orders/order.types';
import { statusNameMask } from 'src/components/Pages/Orders/Components';
import SVG from 'react-inlinesvg';
import WarningIcon from 'src/assets/icons/warning-exclamation.svg';
import { Config } from 'src/config';

interface ReferenceProps {
  order: Order;
}
export const Reference = ({ order }: ReferenceProps) => {
  const { name, bg, border, color } = useMemo(
    () => statusNameMask(order.status as OrdersStatusFilter),
    [order]
  );
  const dispatch = useOrderModalActions();

  const handleCopy = (e: any) => {
    void navigator.clipboard.writeText(e.target.innerHTML);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    toast.success(`${e.target.innerHTML} Copied!`);
  };

  return (
    <>
      <HStack spacing="24px">
        <VStack textAlign="center" w="10%">
          {!Config.PRIMEXPRESS_BUILD && order.internationalDetails?.errors?.length && (
            <Button
              onClick={() => {
                dispatch({ type: 'OPEN_INTERNATIONAL_SHIPMENT_ERRORS', payload: order });
              }}
              variant="link"
              _hover={{ textDecor: 'none' }}
            >
              <Tooltip label="International order has errors">
                <Box>
                  <SVG src={WarningIcon} width="15px" height="15px" fill={'#F6CA45'} />
                </Box>
              </Tooltip>
            </Button>
          )}
          <OrderCheckbox order={order} />
        </VStack>

        <Box>
          <Box h="30px">
            <Text color="primary.900" fontWeight="bold">
              {order.reference}
            </Text>
            <Text color="gray.500" fontSize="text-sm" fontWeight="semibold">
              {order.retailer.name}
            </Text>
          </Box>
          <Box
            h="15px"
            my="3"
            onClick={() => {
              dispatch({ type: 'OPEN_ORDER_DETAILS', payload: order });
            }}
          >
            <Badge
              py="1px"
              fontWeight="medium"
              borderRadius="8px"
              width="110px"
              textAlign="center"
              fontSize="x-small"
              border="1px solid"
              borderColor={border}
              bg={bg}
              color={color}
            >
              {name}
            </Badge>
          </Box>
          <Box h="15px">
            <Text color="gray.500" fontSize="text-s" cursor="copy" onClick={handleCopy}>
              {order.swftboxTracking}
            </Text>
          </Box>
        </Box>
      </HStack>
    </>
  );
};
